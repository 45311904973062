<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <b-card
      :title="$t('Unit')"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <!-- Field: Unit Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="$t('Name')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="unitData.name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Branch -->
            <b-col
              v-if="$can('manage', 'all')"
              cols="12"
              md="4"
            >
              <!-- Item Branch -->
              <b-form-group
                :label="$t('Branch')"
                label-for="branch_id"
              >
                <v-select
                  v-model="unitData.branch_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="branchOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="branch_id"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="gradient-secondary"
              class="mr-1"
              :class="{'col-sm-6': isMobile}"
              pill
              @click="close"
            >
              {{ $t('Close') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              :class="{'col-sm-6': isMobile}"
              pill
              type="submit"
            >
              {{ $t('Save') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from '@/libs/vue-select'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import unitStoreModule from '../unitStoreModule'
import storeModule from '../../../common/storeModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {

  },
  data() {
    return {
      isMobile: false,
      required,
      branchOptions: [],
    }
  },
  mounted() {
    this.updateScreenWidth()
    window.addEventListener('resize', this.updateScreenWidth)
    if (this.$can('manage', 'all')) {
      this.loadBranches()
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth)
  },
  methods: {
    updateScreenWidth() {
      this.isMobile = window.innerWidth <= 768 || window.innerWidth <= 1024 // 768px is a common breakpoint for 'sm' screens
    },
    loadBranches() {
      store
        .dispatch('common/fetchBranches')
        .then(response => {
          const { branches } = response.data.data
          for (let i = 0; i < branches.length; i += 1) {
            this.branchOptions.push({ label: branches[i].name, value: branches[i].id })
          }
          this.unitData.branch_id = branches[0].id
        })
        .catch(error => {
          console.log(error)
        })
    },
    close() {
      if (this.$route.query.ref === 'item') {
        this.$router.push({ name: 'item-new' })
      } else {
        this.$router.push({ name: 'unit-list' })
      }
    },
  },
  setup(props, context) {
    const STORE_MODULE_NAME = 'unit'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, unitStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const employeeData = JSON.parse(localStorage.getItem('employeeData'))

    const blankData = {
      name: '',
      branch_id: parseInt(employeeData.branch_id, 10),
    }

    const router = context.root.$router

    const toast = useToast()
    const unitData = ref(JSON.parse(JSON.stringify(blankData)))
    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(unitData.value))
      store.dispatch('unit/add', data)
        .then(response => {
          if (response.status === 201) {
            if (router.currentRoute.query.ref === 'item') {
              router.push({ name: 'item-new' })
            }
            toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: `New unit "${unitData.value.name}" has been created.`,
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })

            unitData.value = JSON.parse(JSON.stringify(blankData))
            refFormObserver.value.reset()
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    return {
      unitData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
